import { Link } from 'react-ui-kit-exante';

import { ReactComponent as FailedIcon } from '../../../assets/i/Failed.svg';
import { ReactComponent as InProgressIcon } from '../../../assets/i/InProgress.svg';
import { IStatusCell } from '../types';

import { StyledInProgress, StyledDone, StyledFailed } from './styled';

export const StatusCell: ({
  releaseId,
  status,
  buildArtifact,
}: IStatusCell) => JSX.Element = ({ releaseId, status, buildArtifact }) => {
  const { linux, windows, macos } = buildArtifact;
  switch (status) {
    case 'created':
      return (
        <StyledInProgress>
          <InProgressIcon />
          In Progress
        </StyledInProgress>
      );
    case 'done':
    case 'release-started':
    case 'released':
      return (
        <StyledDone>
          {windows && <Link href={windows}>Windows</Link>}
          {linux && <Link href={linux}>Linux</Link>}
          {macos && <Link href={macos}>macOS</Link>}
        </StyledDone>
      );
    case 'error':
      return releaseId ? (
        <StyledDone>
          {windows && <Link href={windows}>Windows</Link>}
          {linux && <Link href={linux}>Linux</Link>}
          {macos && <Link href={macos}>macOS</Link>}
        </StyledDone>
      ) : (
        <StyledFailed>
          <FailedIcon />
          Failed
        </StyledFailed>
      );
    default:
      return <span>status</span>;
  }
};
