import { format } from 'date-fns';
import { IRow } from 'react-ui-kit-exante';

import styles from '../../Application.module.css';
import { ReactComponent as CancelIcon } from '../../assets/i/Cancel.svg';

import { DeployCell } from './TableCells/DeployCell';
import { StatusCell } from './TableCells/StatusCell';
import { IBuild } from './types';

export const convertDateFormat = (date: string) => {
  try {
    const dateObj = new Date(date);

    return format(dateObj, 'dd.MM.yyyy HH:mm:ss');
  } catch (err: unknown) {
    return date;
  }
};

export const getColumns = ({
  triggerRelease,
  onTriggerCancel,
}: {
  triggerRelease: (branding?: string) => void;
  onTriggerCancel: (branding?: string) => void;
}) => [
  {
    Header: 'Branding',
    accessor: 'branding',
  },
  {
    Header: 'Build link',
    accessor: 'buildArtifact',
    Cell: ({ row }: { row: IRow<IBuild> }) => {
      const { buildArtifact, releaseId, status } = row.original;
      return (
        <StatusCell
          releaseId={releaseId}
          status={status}
          buildArtifact={buildArtifact || {}}
        />
      );
    },
    minWidth: 200,
  },
  {
    Header: 'Deploy status',
    accessor: 'releaseArtifact',
    Cell: ({ row }: { row: IRow<IBuild> }) => {
      const { status, branding, platform, releaseId, releaseArtifact } =
        row.original;
      return (
        <DeployCell
          branding={branding}
          triggerRelease={() => triggerRelease(branding)}
          status={status}
          releaseArtifact={releaseArtifact || {}}
          platform={platform}
          releaseId={releaseId}
        />
      );
    },
    minWidth: 200,
  },
  {
    Header: 'Version',
    accessor: 'appVersion',
  },
  {
    Header: 'Released date',
    accessor: ({ updatedAt }: { updatedAt: string }) => {
      return convertDateFormat(updatedAt);
    },
  },
  {
    Header: 'Actions',
    accessor: 'cancel',
    Cell: ({ row }: { row: IRow<IBuild> }) => {
      const { branding, status } = row.original;
      const enabled = status === 'created';
      return (
        <button
          disabled={!enabled}
          className={
            enabled ? styles.ActionsWrapper : styles.ActionsWrapperDisabled
          }
          onClick={() => onTriggerCancel(branding)}
        >
          <CancelIcon />
        </button>
      );
    },
  },
];

export const getCommonColumns = ({
  triggerRelease,
  onTriggerCancel,
}: {
  triggerRelease: () => void;
  onTriggerCancel: (branding?: string) => Promise<void>;
}) => [
  {
    Header: 'Platform',
    accessor: 'platform',
  },
  ...getColumns({ triggerRelease, onTriggerCancel }),
];
