import { FC } from 'react';

import { ReactComponent as AddIcon } from '../../../assets/i/Add.svg';
import { ReactComponent as AddDisabledIcon } from '../../../assets/i/AddDisabled.svg';
import { StyledTriggerBuildButton } from '../styled';
import { ITriggerBuildButton } from '../types';

export const TriggerBuildButton: FC<ITriggerBuildButton> = ({
  brandingName,
  triggerBuild,
}) => {
  const isDisabled = !brandingName;
  return (
    <StyledTriggerBuildButton
      isDisabled={isDisabled}
      onClick={isDisabled ? () => {} : triggerBuild}
    >
      {isDisabled ? <AddDisabledIcon /> : <AddIcon />}
      Trigger Build
    </StyledTriggerBuildButton>
  );
};
