import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { Application } from './Application';
import { store } from './store';
import { ComposedThemeProvider } from './theme/ComposedThemeProvider';

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);

  root.render(
    <StrictMode>
      <Provider store={store}>
        <ComposedThemeProvider>
          <Application />
        </ComposedThemeProvider>
      </Provider>
    </StrictMode>,
  );
};
