import { TDefaultTheme, styled } from 'react-ui-kit-exante';

export const StyledInProgress = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    '& .MuiLink-root': {
      color: theme.color.notifications.warning,
    },
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }),
);

export const StyledDone = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    '& .MuiLink-root': {
      color: theme.color.notifications.success,
    },
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  }),
);

export const StyledFailed = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    '& .MuiLink-root': {
      color: theme.color.notifications.error,
    },
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }),
);
