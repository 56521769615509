import { TTerminalList } from './types';

export const ATP_NAME = 'Desktop platform';
export interface ITerminalOption {
  id: string;
  name: string;
}

export const TERMINAL_LIST: TTerminalList = [
  { id: '0', name: 'All' },
  {
    id: '1',
    name: ATP_NAME,
  },
];

export const DEFAULT_SORT_BY = [{ id: 'createdAt', desc: true }];
