import { TDefaultTheme, styled } from 'react-ui-kit-exante';

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
}));

export const StyledTTRoot = styled('div')(() => ({
  display: 'flex',
}));

export const StyledSelectWrapper = styled('div')(({ theme }) => ({
  minWidth: '240px',
  padding: '24px',
  border: `1px solid ${theme.color.line.primary}`,
  height: '100vh',
}));

export const StyledTableWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.color.line.primary}`,
  height: '100vh',
  width: '100%',
  overflow: 'auto',
}));

export const TableControls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const TableTitle = styled('div')(() => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const TriggerBuildButtonWrapper = styled('div')(() => ({
  display: 'flex',
  marginRight: '32px',
  gap: '16px',
}));

export const StyledTriggerBuildButton = styled('div')(
  ({ isDisabled, theme }: { theme?: TDefaultTheme; isDisabled: boolean }) => ({
    fontSize: '15px',
    lineHeight: '24px',
    color: isDisabled ? theme?.color.icon.secondary : theme?.color.icon.action,
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    fontFamily: theme?.font.main,
    cursor: isDisabled ? 'cursor' : 'pointer',
  }),
);
