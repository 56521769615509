import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { useNodeBackService } from '../../services/NodeBack.service';
import { useAppSelector } from '../../store/hooks';

import { PlatformsSelector } from './selector';

export const useBranding = (platform?: string) => {
  const {
    triggerAtpBuild,
    triggerAtpBuildRelease,
    registerSession,
    getBuildStatus,
    cancelBrandingBuild,
  } = useNodeBackService();
  const { platforms } = useAppSelector(PlatformsSelector);
  const [buildsHistory, setBuildsHistory] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);

  const buildsData = useMemo(() => {
    return Object.values(platforms).flatMap((item) => item.builds);
  }, [platforms]);

  const [brandingName, setBrandingName] = useState<string>('');

  const checkBrandingBuildStatus = (
    name: string | null,
  ): NodeJS.Timeout | undefined => {
    if (typeof name !== 'string') {
      return;
    }

    const intervalId = setInterval(async () => {
      try {
        const updatedBuild = await getBuildStatus(name);

        switch (updatedBuild.status) {
          case 'done': {
            clearInterval(intervalId);
            setIsUpdate(true);
            Notification.success({
              title: 'Branding build has done successfully',
            });
            return;
          }
          case 'released': {
            clearInterval(intervalId);
            setIsUpdate(true);
            Notification.success({
              title: 'Release has done successfully',
            });
            return;
          }
          case 'error': {
            clearInterval(intervalId);
            setIsUpdate(true);
            Notification.error({
              title: updatedBuild?.error,
            });
            return;
          }
          default: {
            return;
          }
        }
      } catch (err: unknown) {
        if (axios.isAxiosError(err) && err.response) {
          if (err.response.status === 404) {
            console.error('err.response', err.response);
          }
        }
        console.error(err);
      }
    }, 16000);

    // eslint-disable-next-line consistent-return
    return intervalId;
  };

  const triggerRelease = useCallback(
    (brandingFromRow?: string) => {
      (async () => {
        const brand = brandingFromRow || brandingName;

        if (!brand) {
          return;
        }
        try {
          const response = await triggerAtpBuildRelease(brand);
          if (response.status === 'release-started') {
            checkBrandingBuildStatus(brand);
            setIsUpdate(true);
            Notification.success({
              title: 'Branding release has been created successfully',
              isPermanent: true,
            });
            return;
          }

          if (response.status === 'released') {
            setIsUpdate(true);
            Notification.success({
              title: 'Branding build has done successfully',
            });
            return;
          }

          if (response.status === 'error') {
            Notification.error({
              title: response?.error,
            });
            setIsUpdate(true);
            return;
          }
        } catch (err: unknown) {
          if (axios.isAxiosError(err) && err.response) {
            if (err.response.status === 400 || err.response.status === 403) {
              Notification.error({
                title: err.response.data.message,
              });
            }
          }
          console.error(err);
        }
      })();
    },
    [brandingName, platform],
  );

  useEffect(() => {
    (async () => {
      try {
        await registerSession();
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    const intervalIds: NodeJS.Timeout[] = [];

    if (buildsData.length === 0) {
      return;
    }

    buildsData.forEach((build) => {
      if (build.status === 'created' || build.status === 'release-started') {
        const intervalId = checkBrandingBuildStatus(build.branding);

        if (intervalId) {
          intervalIds.push(intervalId);
        }
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      intervalIds.forEach((intervalId) => clearInterval(intervalId));
    };
  }, []);

  const cancelBuild = useCallback(async (branding: string) => {
    try {
      const result = await cancelBrandingBuild(branding);
      if (result.status === 'error') {
        setIsUpdate(true);
        Notification.success({
          title: 'Branding build has been canceled successfully',
          isPermanent: true,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return {
    triggerRelease,
    brandingName,
    setBrandingName,
    checkBrandingBuildStatus,
    cancelBuild,
    triggerAtpBuild,
    buildsHistory,
    setBuildsHistory,
    setIsUpdate,
    isUpdate,
  };
};
