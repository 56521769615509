import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { NotificationProvider } from 'react-ui-kit-exante';

import { ErrorFallback } from 'components/ErrorFallback';
import { Header } from 'components/Header/Header';
import { ROUTES } from 'routes';
import { AppVersion } from 'shared/AppVersion/AppVersion';
import { getBaseName } from 'utils/getBaseName';

import styles from './Application.module.css';
import { TradingTerminals } from './pages/TradingTerminals/TradingTerminals';

export const Application = () => {
  const history = window.runUIhistoryContainer;

  const routes = (
    <Switch>
      <Route path={ROUTES.TRADING_TERMINALS}>
        <TradingTerminals />
      </Route>
    </Switch>
  );
  return (
    <>
      <NotificationProvider />
      {window.runUIhistoryContainer ? (
        <Router history={history}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {routes}
          </ErrorBoundary>
        </Router>
      ) : (
        <BrowserRouter basename={getBaseName()}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Header />
            <div className={styles.Container}>
              {routes}
              <AppVersion />
            </div>
          </ErrorBoundary>
        </BrowserRouter>
      )}
    </>
  );
};
