import { FC } from 'react';
import { Table } from 'react-ui-kit-exante';

import { DEFAULT_SORT_BY } from '../const';
import { ICommonTableProps } from '../types';

export const CommonTable: FC<ICommonTableProps> = ({ columns, data }) => {
  return (
    <Table
      data={data}
      columns={columns}
      tableId="All"
      saveColumnOrder
      title="All"
      hasPagination
      isFlexLayout
      defaultSortBy={DEFAULT_SORT_BY}
      manualSortBy
    />
  );
};
