import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { brandingSlice } from '../pages/TradingTerminals/reducer';

const rootReducer = combineReducers({
  [brandingSlice.name]: brandingSlice.reducer,
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
