import { FC, useMemo } from 'react';
import { Panel, Select, Table } from 'react-ui-kit-exante';

import { ReactComponent as CloseIcon } from '../../../assets/i/Close.svg';
import { TriggerBuildButton } from '../TriggerBuildButton/TriggerBuildButton';
import { DEFAULT_SORT_BY } from '../const';
import {
  TableControls,
  TableTitle,
  TriggerBuildButtonWrapper,
} from '../styled';
import { ITerminalTableProps } from '../types';

export const TerminalTable: FC<ITerminalTableProps> = ({
  brandings,
  data = [],
  brandingName,
  setBrandingName,
  columns,
  platform,
  triggerBuild,
  resetPlatform,
}) => {
  const options = useMemo(() => {
    return brandings.map((branding) => ({ value: branding, label: branding }));
  }, [brandings]);

  const handleChangeBranding = (event: any) => {
    setBrandingName(event.target.value);
  };

  return (
    <>
      <TableControls>
        <TableTitle>
          <Panel title={platform} />
          <Select
            defaultValue={brandingName}
            options={options}
            onChange={handleChangeBranding}
            sx={{ minWidth: 195 }}
            label="Branding"
          />
        </TableTitle>
        <TriggerBuildButtonWrapper>
          <TriggerBuildButton
            brandingName={brandingName}
            triggerBuild={triggerBuild}
          />
          <CloseIcon onClick={resetPlatform} style={{ cursor: 'pointer' }} />
        </TriggerBuildButtonWrapper>
      </TableControls>
      <Table
        data={data}
        defaultSortBy={DEFAULT_SORT_BY}
        manualSortBy
        columns={columns}
        tableId="All"
        saveColumnOrder
        hasPagination
        isFlexLayout
      />
    </>
  );
};
