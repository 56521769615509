import { createSlice } from '@reduxjs/toolkit';

import { TERMINAL_LIST } from './const';
import { IBuild, TPlatforms } from './types';

export type TBrandingState = {
  build: IBuild;
  platforms: TPlatforms;
};
const initialState: TBrandingState = {
  build: {
    branding: '',
    version: '',
    buildId: '',
    releaseId: '',
    error: null,
    status: '',
    releasedDate: '',
    platform: '',
    buildArtifact: {},
    releaseArtifact: {},
  },
  platforms: TERMINAL_LIST.reduce<TPlatforms>((acc, { name }) => {
    if (!acc[name]) {
      acc[name] = {
        builds: [],
      };
    }

    return acc;
  }, {}),
};

export const brandingSlice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    addBuild: (state, action) => {
      const { platform, buildId } = action.payload;
      const platformBuilds = state.platforms[platform]?.builds;

      const buildIndex = platformBuilds.findIndex((build) => {
        return build.buildId === buildId;
      });

      if (buildIndex === -1) {
        state.platforms[platform]?.builds.push(action.payload);
      }

      return state;
    },
    updateBuild: (state, action) => {
      const { platform, buildId, ...rest } = action.payload;
      const platformBuilds = state.platforms[platform].builds;

      const buildIndex = platformBuilds.findIndex((build) => {
        return build.buildId === buildId;
      });

      if (buildIndex !== -1) {
        platformBuilds[buildIndex] = {
          ...platformBuilds[buildIndex],
          ...rest,
        };
      }

      return state;
    },
  },
});

export const { addBuild, updateBuild } = brandingSlice.actions;
